import React, { useContext, useEffect, useRef, useState } from 'react'

import { WappContext } from 'wapplr-react/dist/common/Wapp'
import { copyObject } from 'wapplr/dist/common/utils'
import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'

import clsx from 'clsx'

import MenuContext from 'vlm-ui/dist/common/src/components/Menu/context'
import ModalContext from 'vlm-ui/dist/common/src/components/Modal/context'
import Theme from 'vlm-ui/dist/common/src/components/Theme'
import Container from 'vlm-ui/dist/common/src/components/Container'
import AppBar from 'vlm-ui/dist/common/src/components/AppBar'
import Paper from 'vlm-ui/dist/common/src/components/Paper'
import ButtonContext from 'vlm-ui/dist/common/src/components/Button/context'
import ImageContext from 'vlm-ui/dist/common/src/components/Image/context'

import Logo from 'vlm-ui/dist/common/src/components/Logo'

import Snackbar from '../Snackbar'
import AppContext from '../App/context'

import DesktopMenu from './components/DesktopMenu'
import MobileMenu from './components/MobileMenu'
import ThemeControlsDesktop from './components/ThemeControlsDesktop'
import UserMenuDesktop from './components/UserMenuDesktop'
import FeaturedButtons from './components/FeaturedButtons'
import Footer from './components/Footer'

import { ComponentByWidth, getVlmUiFromCookieFunctions } from './components/ComponentByWidth'

import defaultThemes from './theme/index.scss'
import themeCritical from './theme/critical.scss'

import appBarStyle from './additionalComponents/appBar.scss'
import avatarStyle from './additionalComponents/avatar.scss'
import imageStyle from './additionalComponents/image.scss'
import inputStyle from './additionalComponents/input.scss'
import labelStyle from './additionalComponents/label.scss'
import logoStyle from './additionalComponents/logo.scss'
import menuStyle from './additionalComponents/menu.scss'
import menuItemStyle from './additionalComponents/menuItem.scss'
import modalStyle from './additionalComponents/modal.scss'
import paperContentStyle from './additionalComponents/paperContent.scss'
import svgIconStyle from './additionalComponents/svgIcon.scss'

import defaultStyle from './component.css'
import Shapes from '../../postTypes/page/Content/Shapes'

export default function Template(props) {

    const context = useContext(WappContext)
    const appContext = useContext(AppContext)

    const { wapp, req, res } = context

    const utils = getUtils(context)

    const {
        classNames = defaultStyle,
        additionalStyles = {
            appBar: appBarStyle,
            avatar: avatarStyle,
            image: imageStyle,
            input: inputStyle,
            label: labelStyle,
            logo: logoStyle,
            menu: menuStyle,
            menuItem: menuItemStyle,
            modal: modalStyle,
            paperContent: paperContentStyle,
            svgIcon: svgIconStyle
        },
        themes = {
            ...Object.keys(defaultThemes).reduce((o, key) => {
                o[key] = [defaultThemes[key], themeCritical[key]].filter((t) => t).join(' ')
                return o
            }, {})
        },
        children,
        menu = [],
        userMenu = [],
        featuredButtons = [],
        FooterProps = {},
        LogoProps = {},
        handlers = {},
        effect,
        ButtonContextValue = {},
        MenuContextValue = {},
        MenuProps,
        MobileMenuProps,
        UserMenuDesktopProps = {},
        ThemeControlsProps = {},
        BeforeChildren = () => <></>,
        BeforeChildrenProps = {},
        fullPage = true,
        size = 'large',
        disableFooter = false,
        christmas = false
    } = props

    function recMenuType(menu) {
        if (menu?.length) {
            menu.forEach((item) => {
                if (item.items) {
                    if (!item.menuType) {
                        item.menuType = 'list'
                    }
                    recMenuType(item.items)
                }
            })
        }
    }

    recMenuType(menu)

    //wapp.styles.use(themes)
    //wapp.styles.use(classNames)

    const { device } = appContext
    const isMobile = (device.type === 'mobile' || device.type === 'tablet' || device.type === 'wearable')

    const rootContainer = useRef()
    const [, setMountedRootContainer] = useState(false)

    const themeRef = useRef()
    const snackActions = useRef()
    const logoRef = useRef()
    const tipModal = useRef()

    useEffect(() => {

        if (effect) {
            effect({
                actions: {
                    ...themeRef.current?.actions ? themeRef.current.actions : {},
                    setSnackMessage: async function(...attr) {
                        return snackActions?.actions?.setMessage(...attr)
                    },
                    setLogoAnimation: logoRef?.actions?.setRunAnimation,
                    setLogoBlackAndWhite: logoRef?.actions?.setBlackAndWhite
                }
            })
        }
        return () => {
            if (effect) {
                effect({
                    actions: {
                        ...themeRef.current?.actions ?
                            Object.keys(themeRef.current?.actions).reduce((o, key) => {
                                o[key] = async () => {
                                }
                                return o
                            }, {})
                            : {},
                        setSnackMessage: async () => {
                        },
                        setLogoAnimation: async () => {
                        },
                        setLogoBlackAndWhite: async () => {
                        }
                    }
                })
            }
        }
    })

    const {
        setVlmUiToCookie,
        getVlmUiFromCookie
    } = getVlmUiFromCookieFunctions({ req, res, wapp })

    const vlmUiDataFromCookie = getVlmUiFromCookie()

    const route = res.wappResponse.route
    const requestPath = route.requestPath

    return (
        <Theme
            className={clsx(
                classNames.template,
                { [classNames.notFullPage]: !fullPage }
            )}
            componentsStyle={themes}
            classNames={themes}
            additionalStyles={additionalStyles}
            effect={({ actions }) => {
                themeRef.current = {
                    actions
                }
            }}
            handlers={{
                changeTheme: (payload, state) => {
                    if (wapp.target === 'web') {
                        console.log('[APP] Change theme:', payload, state)
                        setVlmUiToCookie()
                    }
                },
                changeMode: (payload, state) => {
                    if (wapp.target === 'web') {
                        console.log('[APP] Change prefer color scheme:', payload, state)
                        setVlmUiToCookie()
                    }
                },
                changeAutoContrast: (payload, state) => {
                    if (wapp.target === 'web') {
                        console.log('[APP] Change auto contrast:', payload, state)
                        setVlmUiToCookie()
                    }
                },
                changeWidth: (payload, state) => {
                    if (wapp.target === 'web') {
                        console.log('[APP] Change width:', payload, state)
                        setVlmUiToCookie()
                    }
                },
                ...handlers
            }}
            themes={{
                theme2: themes.theme2
            }}
            modes={{
                theme2: {
                    light: themes.lightMode,
                    dark: themes.darkMode,
                    auto: themes.autoMode
                }
            }}
            scrollContainer={!fullPage ? rootContainer : undefined}
            device={device}
            useStyle={wapp.styles.use}
            state={{
                setTheme: (value) => {
                    let v = res.wappResponse.store.getState('vlmUi.theme')
                    if (v !== value) {
                        res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', { name: 'theme', value }))
                    }
                },
                getTheme: () => {
                    let v = res.wappResponse.store.getState('vlmUi.theme')
                    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && typeof v === 'undefined') {
                        const data = getVlmUiFromCookie()
                        if (data.theme) {
                            v = data.theme
                        }
                    }
                    return v
                },
                setMode: (value) => {
                    let v = res.wappResponse.store.getState('vlmUi.mode')
                    if (v !== value) {
                        res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', { name: 'mode', value }))
                    }
                },
                getMode: () => {
                    let v = res.wappResponse.store.getState('vlmUi.mode')
                    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && typeof v === 'undefined') {
                        const data = getVlmUiFromCookie()
                        if (data.mode) {
                            v = data.mode
                        }
                    }
                    return v
                },
                setAutoContrast: (value) => {
                    let v = res.wappResponse.store.getState('vlmUi.autoContrast')
                    if (v !== value) {
                        res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', { name: 'autoContrast', value }))
                    }
                },
                getAutoContrast: () => {
                    let v = res.wappResponse.store.getState('vlmUi.autoContrast')
                    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && typeof v === 'undefined') {
                        const data = getVlmUiFromCookie()
                        if (typeof data.autoContrast === 'boolean') {
                            v = data.autoContrast
                        }
                    }
                    return v
                },
                setWidth: (value) => {
                    let v = res.wappResponse.store.getState('vlmUi.width')
                    if (v !== value) {
                        res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', { name: 'width', value }))
                    }
                },
                getWidth: () => {
                    let v = res.wappResponse.store.getState('vlmUi.width')
                    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && typeof v === 'undefined') {
                        const data = getVlmUiFromCookie()
                        if (data.width) {
                            v = data.width
                        }
                    }
                    return v
                },
                getState: () => {
                    let v = res.wappResponse.store.getState('vlmUi')
                    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll')) && !v?.theme) {
                        const data = getVlmUiFromCookie()
                        if (data.theme) {
                            v = data
                        }
                    }
                    return v
                },
                createStates: (actionType) => {
                    if (!wapp.states.stateManager.actions.vlmUi) {
                        wapp.states.stateManager.actions.vlmUi = function({ type, name, value }) {
                            return {
                                type: type || actionType,
                                payload: {
                                    name,
                                    value
                                }
                            }
                        }
                    }
                    if (!wapp.states.stateManager.reducers.vlmUi) {
                        wapp.states.stateManager.reducers.vlmUi = function(state = {}, action) {
                            switch (action.type) {
                                case actionType:
                                    return {
                                        ...state,
                                        [action.payload.name]: (action.payload.value && typeof action.payload.value == 'object') ? copyObject(action.payload.value) : action.payload.value
                                    }
                                default:
                                    return state
                            }
                        }
                    }
                    if ((wapp.target === 'node' || (res.wapplrReactEndType === 'pipe' || res.wapplrReactEndType === 'pipeWaitForAll'))) {
                        const data = getVlmUiFromCookie()
                        if (data.theme) {
                            if (res.wappResponse.store.getState('vlmUi.theme') !== data.theme) {
                                res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', {
                                    name: 'theme',
                                    value: data.theme
                                }))
                            }
                            if (res.wappResponse.store.getState('vlmUi.mode') !== data.mode) {
                                res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', {
                                    name: 'mode',
                                    value: data.mode
                                }))
                            }
                            if (res.wappResponse.store.getState('vlmUi.autoContrast') !== data.autoContrast) {
                                res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', {
                                    name: 'autoContrast',
                                    value: data.autoContrast
                                }))
                            }
                            if (res.wappResponse.store.getState('vlmUi.width') !== data.width) {
                                res.wappResponse.store.dispatch(wapp.states.runAction('vlmUi', {
                                    name: 'width',
                                    value: data.width
                                }))
                            }
                        }
                    }
                },
                getAction: () => {
                    return wapp.states.stateManager.actions.vlmUi
                },
                getReducer: () => {
                    return wapp.states.stateManager.reducers.vlmUi
                },
                subscribe: (...attr) => {
                    return res.wappResponse.store.subscribe(...attr)
                }
            }}
        >
            <ImageContext.Provider value={{
                getSrc: ({ src }) => {
                    if (src && src.startsWith('/thumb')) {
                        return `${src.replace('/thumb', '/thumb/2')}`
                    }
                    return src
                }
            }}>
                <ModalContext.Provider value={{ position: !fullPage ? 'absolute' : 'fixed' }}>
                    <MenuContext.Provider value={MenuContextValue}>
                        <ButtonContext.Provider value={{
                            tipOnOpen: async ({ modal }) => {
                                if (modal.actions !== tipModal.actions && tipModal.actions?.setOpen) {
                                    tipModal.actions.setOpen(false)
                                    tipModal.actions = null
                                }
                                const { actions } = modal
                                tipModal.actions = actions
                            },
                            ...ButtonContextValue
                        }}>
                            <Container
                                className={classNames.root}
                                containerVariant={'root'}
                                ref={async (e) => {
                                    rootContainer.current = e
                                    if (!fullPage) {
                                        await setMountedRootContainer(true)
                                    }
                                }}
                            >
                                <AppBar
                                    className={classNames.appBar}
                                    container-type={'root-root'}
                                >
                                    <div className={classNames.appBarContainer}>
                                        <div className={classNames.appBarLogo}>
                                            <Logo
                                                ComponentProps={{
                                                    href: '/',
                                                    label: appContext.labels.logoAreaLabel
                                                }}
                                                long={true}
                                                {...LogoProps}
                                                effect={({ actions }) => {
                                                    logoRef.actions = actions
                                                    if (LogoProps.effect) {
                                                        LogoProps.effect({ actions })
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={classNames.appBarMenu}>
                                            <ComponentByWidth
                                                ComponentDepWidth={{
                                                    narrow: () => <MobileMenu
                                                        MenuProps={MenuProps}
                                                        menu={copyObject(menu, { keep: 'desktopMegaMenuStaticContent' })}
                                                        userMenu={userMenu}
                                                        classNames={classNames}
                                                        featuredButtons={featuredButtons}
                                                        FooterProps={{
                                                            ...LogoProps ? { LogoProps } : {},
                                                            ...FooterProps ? FooterProps : {}
                                                        }}
                                                        LogoProps={LogoProps}
                                                        ThemeControlsProps={{
                                                            ...ThemeControlsProps,
                                                            themeRef
                                                        }}
                                                        size={size}
                                                        MobileMenuProps={MobileMenuProps}
                                                    />,
                                                    wide: () => <DesktopMenu
                                                        MenuProps={MenuProps}
                                                        menu={copyObject(menu, { keep: 'desktopMegaMenuStaticContent' })}
                                                        classNames={classNames}
                                                        size={size}
                                                    />
                                                }}
                                                {...(!isMobile) ? { InitialComponent: () => null } : null}
                                                Component={() => null}
                                                {...(vlmUiDataFromCookie?.width) ? {
                                                    isMounted: true,
                                                    width: vlmUiDataFromCookie.width
                                                } : {}}
                                            />
                                        </div>
                                        <ComponentByWidth
                                            ComponentDepWidth={{
                                                narrow: () => null,
                                                wide: () => <>
                                                    <ThemeControlsDesktop
                                                        size={size}
                                                        {...ThemeControlsProps}
                                                        classNames={classNames}
                                                        themeRef={themeRef}
                                                    />
                                                    {featuredButtons?.length ?
                                                        <FeaturedButtons
                                                            featuredButtons={featuredButtons}
                                                            classNames={classNames}
                                                            size={size}
                                                        /> : null
                                                    }
                                                    <UserMenuDesktop
                                                        key={Date.now()}
                                                        classNames={classNames}
                                                        size={size}
                                                        menu={userMenu}
                                                        {...UserMenuDesktopProps}
                                                    />
                                                </>
                                            }}
                                            {...(!isMobile) ? { InitialComponent: () => null } : null}
                                            Component={() => null}
                                            {...(vlmUiDataFromCookie?.width) ? {
                                                isMounted: true,
                                                width: vlmUiDataFromCookie.width
                                            } : {}}
                                        />
                                    </div>
                                </AppBar>
                                {
                                    christmas && (
                                        requestPath?.startsWith(appContext.routes.jobsRoute) ||
                                        requestPath?.startsWith(appContext.routes.jobRoute) && route.params?._id && route.path === appContext.routes.jobRoute+"/:_id"
                                    ) ?
                                        <Shapes /> : null
                                }
                                <BeforeChildren {...BeforeChildrenProps} />
                                <Container
                                    Component={Paper}
                                    className={clsx(classNames.page, {[classNames.christmas]: christmas})}
                                    elevation={0}
                                    style={{ borderRadius: 0 }}
                                    container-type={'root-root'}
                                >
                                    {children}
                                </Container>
                                {!disableFooter ?
                                    <Footer
                                        PaperProps={{
                                            'container-type': 'root-root'
                                        }}
                                        classNames={classNames}
                                        key={utils.getRequestUrl()}
                                        {...{
                                            ...LogoProps ? { LogoProps } : {},
                                            ...FooterProps ? FooterProps : {}
                                        }}
                                        size={size}
                                    /> : null
                                }
                            </Container>
                            <Snackbar
                                effect={({ actions }) => {
                                    snackActions.actions = actions
                                }}
                                autoHideDuration={4000}
                            />
                        </ButtonContext.Provider>
                    </MenuContext.Provider>
                </ModalContext.Provider>
            </ImageContext.Provider>
        </Theme>
    )
}
